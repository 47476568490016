<template>
  <div v-if="hasResource('publish_asset_management')">
    <market-list></market-list>
  </div>
</template>

<script>
import { marketList } from './mkt-components/mkt-index'
export default {
  components: { marketList },
  data() {
    return {
      header: {
        url: "marketAssets",
      },
    };
  },
};
</script>

