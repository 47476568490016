<template>
  <!-- 市场资产搜索 -->
  <div class="dis-fix mkt-search-filter" @click="handleClose">
    <el-form ref="queryFormRef" :model="queryForm" :inline="true">
      <el-form-item label="外部ID" prop="asset_uid">
        <el-input
          v-model="queryForm.asset_uid"
          clearable
          @keyup.enter.native="addByEnterKey"
          placeholder="请输入外部ID"
        ></el-input>
      </el-form-item>
      <el-form-item label="资产名称" prop="name">
        <el-input
          v-model="mktAssetNameId"
          clearable
          @keyup.enter.native="addByEnterKey"
          placeholder="请输入资产名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="资产类型">
        <el-select v-model="queryForm.type">
          <el-option
            v-for="item in assetsType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="资产分类">
        <el-cascader
          v-model="category"
          :props="categoryParams"
          :options="categories"
          ref="cascaderAddr"
          @change="handleCategoryChange"
          @remove-tag="removeTag"
        ></el-cascader>
      </el-form-item>

      <el-form-item label="发布时间" prop="date">
        <el-date-picker
          v-model="queryForm.date"
          type="daterange"
          range-separator="-"
          style="width: 168px"
          value-format="yyyy-MM-dd"
          start-placeholder="请选择"
          end-placeholder="请选择"
          @change="handleDateChange"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="首字母">
        <el-select v-model="queryForm.asset_initials">
          <el-option value label="全部"></el-option>
          <el-option
            v-for="item in letters"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- <div style="display: inline-block">
        <el-form-item
          label="价格"
          prop="cost.from"
          style="margin-right: 0px !important"
        >
          <el-input
            v-model="queryForm.cost.from"
            style="width: 80px !important"
            @keyup.enter.native="addByEnterKey"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <span style="lineHeight: 40px">{{ "\n-\n" }}</span>
        <el-form-item prop="cost.to">
          <el-input
            v-model="queryForm.cost.to"
            style="width: 80px !important"
            @keyup.enter.native="addByEnterKey"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </div> -->
      <el-form-item label="颜色属性">
        <div class="color-name" @click.stop="handleShow($event)">
          <div v-if="queryForm.color.length === 0">请选择颜色属性</div>
          <div v-if="queryForm.color.length > 3" class="color-three-item">
            <div
              class="color-blockage"
              :class="
                queryForm.color[0] == '#FFFFFF'
                  ? 'color-item-border'
                  : 'color-border-eee'
              "
              :style="`background:${queryForm.color[0]}`"
            >
              <div
                :class="queryForm.color[0] == '' ? 'item-none-slash' : ''"
              ></div>
            </div>
            <div
              class="color-blockage"
              :class="
                queryForm.color[1] == '#FFFFFF'
                  ? 'color-item-border'
                  : 'color-border-eee'
              "
              :style="`background:${queryForm.color[1]}`"
            >
              <div
                :class="queryForm.color[1] == '' ? 'item-none-slash' : ''"
              ></div>
            </div>
            <div class="color-blockage-over-three">
              <div class="color-blockage-dot"></div>
              <div class="color-blockage-dot"></div>
              <div class="color-blockage-dot"></div>
            </div>
          </div>
          <div
            v-else
            class="width-30 height-30 color-number"
            v-for="(item, index) in queryForm.color"
            :key="index"
            :style="`background:${item}`"
            :class="
              item == '#FFFFFF' ? 'color-item-border' : 'color-border-eee '
            "
          >
            <div :class="item == '' ? 'item-none-slash' : ''"></div>
          </div>
          <div class="color-icon-down">
            <i v-if="!colorShow" class="el-icon-arrow-down"></i>
            <i v-if="colorShow" class="el-icon-arrow-up"></i>
          </div>
        </div>
        <div
          class="color-items"
          :class="!colorShow ? 'display-none' : 'display-block'"
        >
          <div class="color-bor-items">
            <div
              class="width-30 height-30 color-child-content"
              v-for="(item, index) in colors"
              :key="index"
              :style="`background:${item === '' ? '#FFFFFF' : item}`"
              @click.stop="chooseColor(item, index, $event)"
              :class="
                item === '' && queryForm.color.includes(item)
                  ? 'bor-red'
                  : queryForm.color.includes(item) &&
                    item !== '' &&
                    item != '#FFFFFF'
                  ? 'bor-white'
                  : item == '#FFFFFF' && queryForm.color.includes(item)
                  ? 'bor-black'
                  : ''
              "
            >
              <div :class="item == '' ? 'color-none-slash' : ''"></div>
              <i
                v-if="queryForm.color.includes(item) && item !== ''"
                class="iconfont icon-position color-index"
                :class="item == '#FFFFFF' || item == '' ? 'black' : 'white'"
                >&#xe607;</i
              >
            </div>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div class="text-btn">
      <div class="serach-button" @click="mktSearch">查询</div>
      <div class="clear-button" @click="clearSearch">清空</div>
    </div>
  </div>
</template>

<script>
import classifyFilter from '@/mixins/asset/classify.js';
import api from "@/api/assets";

export default {
  name: "MarketAssetSearch",
  mixins: [classifyFilter],
  props: {
    updateList: {
      type: Function,
      default: () => {
        return Function();
      },
    },
  },
  data() {
    return {
      colorShow: false,
      hasColor: false,
      value: "",
      categories: [],
      category: [],
      query: {
        limit: 10,
        order_by: "published_at",
        page: 1,
        sort: "DESC",
        filter: {},
        search: "",
      },
      queryForm: {
        date: "",
        asset_uid: "",
        name: "",
        category_id: [],
        status: 5,
        published_at: {
          from: "",
          to: "",
        },
        cost: {
          from: "",
          to: "",
        },
        asset_initials: "",
        type: "",
        color: [],
      },
      categoryParams: {
        label: "name_en",
        value: "id",
        children: "child",
        // checkStrictly: true,
        multiple: true,
      },
      letters: [],
      assetsType: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "4",
          label: "3D Assets",
        },
        {
          value: "1",
          label: "Surface",
        },
        {
          value: "3",
          label: "Decals",
        },
      ],
      assetNameId: "",
      mktAssetNameId: "",
      colors: [
        "",
        "#B8342B",
        "#FC6B33",
        "#F2CE58",
        "#519662",
        "#1E3783",
        "#593562",
        "#242424",
        "#FFFFFF",
        "#ABAEAA",
      ],
      changeTrue: false,
    };
  },
  methods: {
    addByEnterKey(e) {
      if (e.keyCode == 13) {
        this.mktSearch();
      }
    },
    getCategories() {
      api.categoryList().then((res) => {
        if (res.data.code === 10200) {
          this.categories = this.handleFilterData(res.data.data.items);
        }
      });
    },

    handleCategoryChange(val) {
      if (this.changeTrue) {
        this.changeTrue = false;
        return;
      }
      this.queryForm.category_id = [];
      this.$refs.cascaderAddr.getCheckedNodes().forEach((item, index) => {
        if (item.checked == true) {
          this.queryForm.category_id.push(item.value);
        }
      });
      this.queryForm.category_id = Array.from(
        new Set(this.queryForm.category_id)
      );
    },
    removeTag(val) {
      this.changeTrue = true;
      this.$refs.cascaderAddr.getCheckedNodes().forEach((item, index) => {
        val.forEach((el) => {
          this.queryForm.category_id.forEach((e, index) => {
            if (el === e) {
              this.queryForm.category_id.splice(index, 1);
            }
          });
        });
      });
    },
    handleDateChange(val) {
      if (val) {
        const startDate = val[0] + " 00:00:00";
        const endDate = val[1] + " 23:59:59";
        this.queryForm.published_at.from = new Date(startDate).getTime()/1000;
        this.queryForm.published_at.to = new Date(endDate).getTime()/1000;;
      } else {
        (this.queryForm.published_at.from = ""),
          (this.queryForm.published_at.to = "");
      }
    },

    mktSearch() {
      // const categoryInfo = this.category;
      // this.queryForm.category_id = categoryInfo[categoryInfo.length - 1] || "";
      this.queryForm.asset_uid = this.queryForm.asset_uid.trim();
      var strArr = this.mktAssetNameId.split(""); //将字符串进行拆分
      var reg = /^[A-Z]+$/; //正则A-Z
      let newStr = "";
      for (let i = 0; i < this.mktAssetNameId.length; i++) {
        if (reg.test(strArr[i]) && i != 0 && strArr[i - 1] !== " ") {
          strArr[i] = " " + strArr[i];
        }
        newStr += strArr[i];
      }
      this.assetNameId = newStr;
      this.queryForm.name = newStr.trim();
      this.queryForm.type_ids = [this.queryForm.type];
      this.query.filter = { ...this.queryForm };
      this.updateList(this.query);
    },

    clearSearch() {
      this.$nextTick(() => {
        this.$refs["queryFormRef"].resetFields();
      });
      this.category = [""];
      this.queryForm.asset_initials = "";
      this.queryForm.type = '';
      this.queryForm.type_ids = [];
      this.mktAssetNameId = "";
      this.queryForm.color = [];
      this.queryForm.category_id = [];
    },
    handleShow() {
      this.colorShow = !this.colorShow;
    },
    chooseColor(color, indexs) {
      if (this.queryForm.color.includes(color)) {
        this.queryForm.color.forEach((item, index) => {
          if (item == color) {
            this.queryForm.color.splice(index, 1);
          }
        });
        return;
      }
      this.hasColor = !this.hasColor;
      this.queryForm.color.push(color);
      this.queryForm.color = Array.from(new Set(this.queryForm.color));
    },
    handleColor(color) {},
    handleClose() {
      this.colorShow = false;
    },
  },
  mounted() {
    this.getCategories();
    this.getAllLetters();
  },
};
</script>

<style lang="less">
@import "./scss/mkt-list.less";
</style>
