const mixin = {
    methods: {
        /**
         * 资产分类数据处理。最后子节点child是[], 前端显示有问题
         * @param {string} data data
         * @returns {*}
        */
        handleFilterData(data) {
            const _list = data.map((item) => {
                item.child = item.child && item.child.length ? item.child : null;
                if (item.child) {
                    item.child = this.handleFilterData(item.child);
                }
                return item;
            });
            return _list;
        }
    }
};

export default mixin;
