import axios from "../uitls/require";

export default {
  // 导入
  import(info) {
    return axios.post("/admin/asset/import", info, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  // 更新
  update(info) {
    return axios.post("/admin/asset/update", info, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  // list
  assetsList(info) {
    return axios.post("/admin/asset/list", info);
  },
  // info
  assetsInfo(id) {
    return axios.get("/admin/asset?market=US&id=" + id);
  },
  // 下载
  download(info) {
    return axios.post("/admin/asset/download", info);
  },
  // 分类数据
  categroy() {
    return axios.get("/admin/category/tree?market=US");
  },
  categoryList(market) {
    return axios.post("/admin/category/list", market);
  },
  // 核验
  verify(data) {
    return axios.post("/admin/asset/verify", data);
  },
  // 删除
  assetDelete(data) {
    return axios.post("/admin/asset/delete", data);
  },
  // 作废
  discardDelete(data) {
    return axios.post("/admin/asset/discard", data);
  },
  // 发布
  publishReview(data) {
    return axios.post("/admin/asset/publish-review", data);
  },
  // 撤回
  withDraw(data) {
    return axios.post("/admin/asset/cancel-review", data);
  },
  // 重置中
  remake(data) {
    return axios.post("/admin/asset/remake", data);
  },
  // 市场资产列表
  marketAssetInfo(id) {
    return axios.get("/admin/publish-asset?market=US&id=" + id);
  },
  //资产库列表
  assetCentre(data) {
    return axios.post("/admin/asset-centre/list",data)
  },
  //资产移动
  moveAsset(data) {
    return axios.post('/admin/asset-centre/move', data)
  },
  // 资产库资产详情
  assetCentreInfo(data) {
    return axios.get(`/admin/asset-centre/info?id=${data.id}`);
  },
  //资产删除
  assetDelete(data) {
    return axios.post('/admin/asset/delete', data)
  },
  //免费资产列表
  assetFree(data) {
    return axios.post("/admin/asset/free/list",data)
  },
  //添加免费资产
  addFree(data) {
    return axios.post("/admin/asset/free/add",data)
  },
  //取消免费资产
  cancelFree(data) {
    return axios.post("/admin/asset/free/cancel",data)
  },
  //组合资产列表
  assetProperty(data) {
    return axios.post("/admin/asset/group/list",data)
  },
  //编辑组合资产
  editAssetProperty(data) {
    return axios.post("/admin/asset/group/edit",data)
  },
  //组合资产详情
  assetPropertyInfo(data) {
    return axios.get(`/admin/asset/group/info?group_id=${data}`);
  },
  //启用停用 ，修改组合资产状态
  setAssetPropertyStatus(data) {
    return axios.post("/admin/asset/group/change-status",data)
  },
};
