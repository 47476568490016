import axios from '../uitls/require';

export default {
    getAssetList(queryData = { filter: {}}, market='US') {
        const query = {...queryData}
        query.filter.status = 5;
        query.market = market
        return axios.post('/admin/publish-asset/list', query)
    },
    assetDownload(rowId, market="US") {
        const params = {compressed: 1, id: rowId, market: market}
        return axios.post('/admin/asset/download', params)
    },
    assetDestock(items, market = 'US') {
        const params = {...items, market: market}
        return axios.post('/admin/asset/destock', params)
    },
    getCategoryInfo(market = 'US') {
        return axios.get('/admin/category/tree?market='+ market)
    },
    imgSync(params) {
        return axios.post(`/admin/asset/clear-image-cache`,params)
    },
    assetSync(ids, market="US") {
        let params = {ids: ids, market: market}
        return axios.post(`/admin/asset/reindex`, params)
    },
    // 导出
    assetExport(params) {
        return axios.post(`/admin/publish-asset/export`, params)
    },
}
