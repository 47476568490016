<template>
  <div>
    <el-dialog
      top="12%"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="458px"
      :modal-append-to-body="false"
      class="pop-dialog"
    >
      <div class="sync-title">
        <div class="mg-r-10 sync-left"><h4>是否同步资产数据至PBRMAX?</h4></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          class="confirm-button"
          @click="assetSync"
          size="medium"
          :loading="btnLoading"
          >确认</el-button
        >
        <el-button @click="hide" class="cancel-button">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import marketApi from "@/api/mkt-assets";

export default {
  name: "SyncAssets",
  data() {
    return {
      dialogVisible: false,
      ids: [],
      btnLoading:false
    };
  },

  methods: {
    assetSync() {
      this.btnLoading=true;
      marketApi.assetSync(this.ids).then((res) => {
        if (res.data.code === 10200) {
          this.hide();
          this.$$success("更新成功");
        } else {
        }
        this.btnLoading=false;
      });
    },

    show(items) {
      this.dialogVisible = true
      this.ids = items
    },

    hide() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
h4 {
  font-size: 18px;
  font-weight: 500;
}

.sync-title {
  display: flex;
  padding: 20px 0 20px 12px;
  justify-content: flex-start;
  .sync-right {
    margin-top: -3px;
    width: 55%;
    h3 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
