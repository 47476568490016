<template>
  <div>
    <el-dialog
      top="12%"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="458px"
      :modal-append-to-body="false"
      class="pop-dialog"
    >
      <div  v-if="singleItem">
        <div class="del-title">
            <div class="mg-r-10 del-left">
            <h4>您将下架资产</h4>
            </div>
            <div class="mg-l-10 del-right">
            <h3 class="line-height-34">{{marketItems[0].name}}</h3>
            </div>
        </div>

      </div>

      <div v-else class="del-title">
        <div class="mg-r-10">
          <h4>您将批量下架所选择的资产</h4>
        </div>
      </div>
      <div class="del-res">下架原因</div>
      <el-input v-model="desc" placeholder="请输入原因"  type="textarea" :show-word-limit="true" :rows="5" :maxLength="120"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" class="confirm-button" :disabled="disabled" @click="destockMarketItem" size="medium" :loading="btnLoading">确认</el-button>
        <el-button @click="hide" class="cancel-button">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import marketApi from "@/api/mkt-assets";

export default {
  name: "RemoveDialog",
  props: ["updateAssetList"],
  watch: {
    "desc": function (val, oldVal) {
        this.disabled = val ? false : true;;
    },
  },
  data() {
    return {
      dialogVisible: false,
      marketItems: [],
      singleItem: false,
      btnLoading:false,
      desc: "",
      disabled: true
    };
  },

  methods: {
    destockMarketItem() {
      this.btnLoading=true;
      let ids = [];
      this.marketItems.forEach((item) => {
        ids.push(item.id);
      });
      let data = {
          ids: ids,
          desc: this.desc
      }
      marketApi.assetDestock(data).then((res) => {
        if (res.data.code === 10200) {
          this.hide();
          this.$$success("下架成功");
          this.updateList();
          this.updateAssetList()
        }
      });
    },
    updateList() {
      this.$emit("updateList");
    },

    show(item) {
      this.dialogVisible = true;
       this.desc = '';
       this.disabled = true;
       this.btnLoading = false;
      if (Array.isArray(item)) {
        this.marketItems = [...item];
      } else {
        this.marketItems = [item];
        this.singleItem = true;
      }
    },

    hide() {
      this.dialogVisible = false;
      this.btnLoading=false;
    },
  },
};
</script>

<style lang="less" scoped>
h4 {
  font-size: 18px;
}
h3 {
  font-size: 24px;
  font-weight: 700;
}
.del-res {
    font-size: 18px;
    margin: 10px 0px;
}
.del-title {
  display: flex;
  // padding: 0;
  // justify-content: center;
  .del-right {
    margin-top: -10px;
    width: 55%;
    h3 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
