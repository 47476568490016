<template>
  <div>
    <div v-if="hasResource('publish_asset_management')" @click="handleClose">
      <div class="search-form">
        <el-button
          class="black-btn"
          v-if="hasResource('publish_asset_remove')"
          @click="handleBulkDestock"
          >批量下架</el-button
        >
        <!-- <el-button class="white-btn" @click="handleMassUpdate"
          >批量更新</el-button
        > -->
        <!-- <el-button
          class="white-btn"
          v-if="hasResource('publish_asset_reindex')"
          @click="handleAssetsSync"
          >同步资产</el-button
        > -->
        <!-- <el-button
          class="black-btn"
          v-if="hasResource('publish_asset_export')"
          @click="handleAssetsExport"
          :loading="exportBtnLoading"
          >导出</el-button
        > -->
        <market-asset-search
          :updateList="getMarketList"
          ref="child"
        ></market-asset-search>
      </div>
      <!-- 市场资产表格 -->
      <el-table
        :data="listData"
        style="width: 100%"
        class="data-table"
        header-cell-class-name="table-head"
        cell-class-name="table-cell"
        v-loading="tableLoading"
        ref="mktList"
        @selection-change="handleSelectionChange"
        :default-sort="{ prop: 'published_at', order: 'descending' }"
        @sort-change="sortChange"
      >
        >
        <el-table-column type="selection" width="120px"></el-table-column>
        <el-table-column
          align="left"
          width="170px"
          prop="preview"
          label="缩略图"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <img :src="scope.row.preview+ '?imageMogr2/thumbnail/x128/ignore-error/1/interlace/1'" class="max-width-64 max-height-64" />
          </template>
        </el-table-column>
        <el-table-column
          label="外部ID"
          prop="asset_uid"
          width="180px"
          show-overflow-tooltip
          sortable="custom"
        ></el-table-column>
        <el-table-column
          label="资产名称"
          prop="name"
          width="180px"
          show-overflow-tooltip
          sortable="custom"
        ></el-table-column>
        <el-table-column
          label="资产类型"
          prop="type"
          width="160px"
          show-overflow-tooltip
          sortable="custom"
        ></el-table-column>
        <el-table-column
          label="资产分类"
          min-width="260px"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.categories.length === 0">--</span>
            <span v-for="(item, index) in scope.row.categories" :key="index">
              {{ item.label }}
              <span
                v-if="
                  scope.row.categories.length > 1 &&
                  scope.row.categories.length - 1 !== index
                "
                >,</span
              >
            </span>
          </template>
        </el-table-column>

        <el-table-column
          label="发布时间"
          prop="published_at"
          width="180px"
          show-overflow-tooltip
          sortable="custom"
        >
          <template slot-scope="scope">
            {{scope.row.published_at | dateFilter}}
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="发布市场"
          prop="market"
          width="120px"
          show-overflow-tooltip
        ></el-table-column> -->
        <!-- <el-table-column
          label="价格"
          prop="cost"
          width="120px"
          show-overflow-tooltip
          sortable="custom"
        ></el-table-column> -->
        <el-table-column
          label="下载量"
          prop="download_count"
          width="120px"
          show-overflow-tooltip
        ></el-table-column>

        <el-table-column
          label="颜色属性"
          width="155px"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div v-if="scope.row.color.length === 0">--</div>
            <div
              v-for="(item, index) in scope.row.color"
              :key="index"
              class="color-contents"
              :style="`background:${item}`"
              :class="item === '#FFFFFF' ? 'border-1-black' : ''"
            >
              <div class="display-none">
                {{ item }}
                <span
                  v-if="
                    scope.row.color.length > 1 &&
                    scope.row.color.length - 1 !== index
                  "
                  >,</span
                >
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="400">
          <!-- 条目操作 -->
          <template slot-scope="scope">
            <div class="icon-btn">
              <el-button
                class="preview-color"
                size="mini"
                type="text"
                v-if="hasResource('publish_asset_info')"
                @click="handleView(scope.row)"
              >
                <i class="iconfont">&#xe662;</i> 查看
              </el-button>
              <!-- <el-button
                class="preview-color"
                size="mini"
                type="text"
                v-if="hasResource('publish_asset_clear_image')"
                @click="handleImgSync(scope.row)"
              >
                <i class="iconfont">&#xe666;</i> 更新
              </el-button> -->
              <el-button
                class="preview-color"
                size="mini"
                type="text"
                @click="handleChange(scope.row)"
                v-if="hasResource('publish_asset_edit')"
              >
                <i class="iconfont">&#xe673;</i> 变更
              </el-button>
              <el-button
                class="preview-color"
                size="mini"
                type="text"
                v-if="hasResource('publish_asset_remove')"
                @click="handleDestock(scope.row)"
              >
                <i class="iconfont">&#xe660;</i> 下架
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div class="ta-r mg-t-18">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-sizes="[10, 20, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="listInfo.total"
          v-if="listInfo.total != 0"
        ></el-pagination>
      </div>
      <!-- 弹窗 -->
      <div>
        <remove-dialog
          ref="destockPop"
          @updateList="getMarketList"
        />
        <!-- <sync-image ref="syncImgPop"></sync-image>
        <sync-assets ref="syncAssetsPop"></sync-assets> -->
      </div>
    </div>
  </div>
</template>
<script>
import { formatDate } from 'ecoplants-lib';
import marketApi from "@/api/mkt-assets";
import marketAssetSearch from "./mkt-search";
import { removeDialog, /**syncImage, syncAssets */ } from "./dialogs";

export default {
  name: "MarketList",
  components: { removeDialog, marketAssetSearch, /** syncImage, syncAssets */ },
    filters: {
        /**
         * 时间格式化
         * @param {number} val 时间戳
         * @returns {string}
         */
        dateFilter(val) {
            return val ? formatDate(val * 1000, 'yyyy-MM-dd hh:mm:ss') : '--';
        }
    },
  data() {
    return {
      listData: [],
      listInfo: {},
      tableLoading: false,
      itemsSelected: [],
      pageSize: 10,
      currentPage: 1,
      order_by: "published_at",
      sort: "DESC",
      recordDatd: {},
      parameters: false
    //   exportBtnLoading: false // 导出按钮loading
    };
  },

  methods: {
    async init() {
      if (this.getLocalCurrentPage("needUpdate")) {
        await this.updateFilterParams();
        await this.getMarketList();
        this.recordCurrentPage("needUpdate", false);
      } else {
        await this.getMarketList();
      }
    },
    getMarketList(info = { market: "US", filter: {} }) {
      info.filter.name = this.$refs.child.assetNameId;
      info.filter = this.parameters
        ? this.recordDatd
        : this.$refs.child.queryForm;
      this.tableLoading = true;
      const currentQuery = { ...info };
      currentQuery.limit = this.parameters
        ? this.recordDatd.limit
        : this.pageSize;
      currentQuery.page = this.parameters
        ? this.recordDatd.page
        : this.currentPage;
      currentQuery.order_by = this.order_by;
      currentQuery.sort = this.sort;
      marketApi.getAssetList(currentQuery).then((res) => {
        if (res.data.code === 10200) {
          this.listInfo = res.data.data;
          this.listData = res.data.data.items;
          if (this.listData.length <= 0 && this.currentPage > 1) {
            this.currentPage = 1;
            this.getMarketList();
          }
        }
        this.tableLoading = false;
      });
    },

    updateFilterParams() {
      this.recordDatd = this.getLocalCurrentPage("marketList0415");
      let params = this.recordDatd;
      if (params) {
        this.parameters = true;
        this.currentPage = params ? params.page : this.currentPage;
        this.pageSize = params ? params.limit : this.pageSize;
        this.$refs.child.queryForm.asset_uid = params
          ? params.asset_uid
          : this.$refs.child.queryForm.asset_uid;
        this.$refs.child.queryForm.name = params
          ? params.asset_name
          : this.$refs.child.queryForm.name;
        this.$refs.child.queryForm.type = params
          ? params.type
          : this.$refs.child.queryForm.type;
        this.$refs.child.queryForm.category_id = params
          ? params.category_id
          : this.$refs.child.queryForm.category_id;
        this.$refs.child.queryForm.published_at = params
          ? params.published_at
          : this.$refs.child.queryForm.published_at;
        this.$refs.child.queryForm.cost = params
          ? params.cost
          : this.$refs.child.queryForm.cost;
        this.$refs.child.queryForm.asset_initials = params
          ? params.asset_initials
          : this.$refs.child.queryForm.asset_initials;
      }
      this.getMarketList();
    },
    // 查看
    handleView(row) {
      this.$router.push({
        path: "/mkt-info",
        query: {
          id: row.id,
        },
      });
      const params = {
        limit: this.pageSize,
        page: this.currentPage,
        asset_uid: this.$refs.child.queryForm.asset_uid,
        asset_name: this.$refs.child.queryForm.name,
        type: this.$refs.child.queryForm.type,
        category_id: this.$refs.child.queryForm.category_id,
        published_at: this.$refs.child.queryForm.published_at,
        cost: this.$refs.child.queryForm.cost,
        asset_initials: this.$refs.child.queryForm.asset_initials,
      };
      this.recordCurrentPage("marketList0415", params);
    },

    handleImgSync(row) {
      this.$nextTick(() => {
        this.$refs["syncImgPop"].show(row);
      });
    },

    // 变更
    handleChange(row) {
      this.$router.push({
        path: "/asset-change",
        query: {
          id: row.id,
        },
      });
      // marketApi.assetDownload(row.id).then((res) => {
      //   if (res.data.code === 10200) {
      //     this.downloadFile(res.data.data.data.url[0]);
      //     this.$nextTick(() => {
      //       this.$$success("已下载");
      //     });
      //   }
      // });
    },

    handleDestock(row) {
      this.$nextTick(() => {
        this.$refs["destockPop"].show(row);
      });
    },

    // 批量下架
    handleBulkDestock() {
      const marketItems = this.itemsSelected;
      if (marketItems.length > 0) {
        this.$nextTick(() => {
          this.$refs["destockPop"].show(marketItems);
        });
      } else {
        this.$$error("请先勾选数据~");
      }
    },

    handleMassUpdate() {
      const marketItems = this.itemsSelected;
      if (marketItems.length > 0) {
        this.$nextTick(() => {
        this.$refs["syncImgPop"].show(marketItems);
        });
      } else {
        this.$$error("请先勾选数据~");
      }
    },

    handleAssetsSync() {
      const marketItems = [];
      this.$nextTick(() => {
        this.$refs["syncAssetsPop"].show(marketItems);
      });
    },

    handleSelectionChange(val) {
      this.itemsSelected = val;
    },

    handleSizeChange(val) {
      this.parameters = false;
      this.pageSize = val;
      this.getMarketList();
    },
    handleCurrentChange(val) {
      this.parameters = false;
      this.currentPage = val;
      this.getMarketList();
    },
    sortChange(column) {
      this.order_by = column.prop;
      this.sort = column.order == "descending" ? "DESC" : "ASC";
      this.getMarketList();
    },
    handleClose() {
      this.$nextTick(() => {
        this.$refs.child.handleClose();
      });
    },
    // // 导出
    // handleAssetsExport() {
    //     this.exportBtnLoading = true;
    //     const currentQuery = {
    //         filter: {
    //             name: this.$refs.child.assetNameId,
    //             ...this.$refs.child.queryForm
    //         },
    //         order_by: this.order_by,
    //         sort: this.sort,
    //         search: '',
    //         market: 'US'
    //     };
    //     marketApi.assetExport(currentQuery).then((res) => {
    //         if (res.data.code === 10200) {
    //             const url = res.data.data.export_url;
    //             this.downloadFile(url);
    //         } else {
    //             this.$$error(res.data.message);
    //         }
    //         this.exportBtnLoading = false;
    //     }).catch((error) => {
    //         this.$$error(error);
    //         this.exportBtnLoading = false;
    //     });
    // }
  },
  mounted() {
    this.msgBus.$on("marketList", () => {
      this.recordCurrentPage("needUpdate", true);
    });
    this.init();
  },
  activated() {
    if (this.$route.meta.keepAlive) {
      this.getMarketList();
    }
  },
};
</script>
<style lang="less">
@import "./scss/mkt-list.less";
</style>
